import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';

interface CartItem {
  id: string;
  name: string;
  image: string;
  size: string;
  price: string;
  quantity: number;
}

interface AddedItem {
  productName: string;
  quantity: number;
  price: string;
  image: string;
  size: string;
}

interface CartContextType {
  cart: CartItem[];
  addToCart: (item: CartItem) => void;
  removeFromCart: (id: string, size: string) => void;
  clearCart: () => void;
  addedItem: AddedItem | null;
  isCartEmpty: boolean;
}

interface CartProviderProps {
  children: ReactNode;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const [cart, setCart] = useState<CartItem[]>(() => {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });

  const [addedItem, setAddedItem] = useState<AddedItem | null>(null);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (item: CartItem) => {
    setCart(prevCart => {
      const existingItemIndex = prevCart.findIndex(
        cartItem => cartItem.id === item.id && cartItem.size === item.size
      );
      if (existingItemIndex !== -1) {
        const updatedCart = [...prevCart];
        const existingItem = updatedCart[existingItemIndex];
        updatedCart[existingItemIndex] = {
          ...existingItem,
          quantity: Math.min(existingItem.quantity + item.quantity, item.quantity) // Prevent exceeding available quantity
        };
        return updatedCart;
      }
      return [...prevCart, item];
    });
    setAddedItem({
      productName: item.name,
      quantity: item.quantity,
      price: item.price,
      image: item.image,
      size: item.size
    });
    setTimeout(() => setAddedItem(null), 2000);
  };

  const removeFromCart = (id: string, size: string) => {
    setCart(prevCart => prevCart.filter(item => item.id !== id || item.size !== size));
  };

  const clearCart = () => {
    setCart([]);
  };

  const isCartEmpty = cart.length === 0;

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart, addedItem, isCartEmpty }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextType => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
