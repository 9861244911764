import React from 'react'
import Header from '../../../components/Header'
import './index.css'
import Footer from '../../../components/Footer'

function Terms() {
  return (
    <div className='container'>
        <Header />
        <div className='privacy-page'>
            <div className='privacy-title'>Политика использования</div>

            <div className='privacy-h2'>Платежи. Оплата банковской картой онлайн</div>
            <div className='privacy-text'>Наш сайт подключен к интернет-эквайрингу, и Вы можете оплатить Услугу банковской картой Visa или Mastercard. После подтверждения выбранного Товара либо услуги откроется защищенное окно с платежной страницей процессингового центра TipTop pay, где Вам необходимо ввести данные Вашей банковской карты. Для дополнительной аутентификации держателя карты используется протокол 3-D Secure. Если Ваш Банк-эмитент поддерживает данную технологию, Вы будете перенаправлены на его сервер для прохождения дополнительной идентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.</div>
            <div className='privacy-text'>Услуга онлайн-оплаты осуществляется в соответствии с правилами Международных платежных систем Visa и MasterCard на принципах соблюдения конфиденциальности и безопасности совершения платежа, для этого используются самые актуальные методы проверки, шифрования и передачи данных по закрытым каналам связи. Ввод данных банковской карты осуществляется в защищенном окне на платежной странице TipTop pay.</div>
            <div className='privacy-text'>В поля на платежной странице требуется ввести номер карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA или CVC2 для MasterCard). Все необходимые данные отображены на поверхности банковской карты.</div>
            <div className='privacy-text'>CVV2/ CVC2 — это трёхзначный код безопасности, находящийся на оборотной стороне карты.</div>
            <div className='privacy-text'>Далее в том же окне откроется страница Вашего банка-эмитента для ввода 3-D Secure кода. В случае, если у вас не настроен статичный 3-D Secure, он будет отправлен на ваш номер телефона посредством SMS. Если 3-D Secure код к Вам не пришел, то следует обратится в ваш банк-эмитент.</div>
            <div className='privacy-text'>3-D Secure — это самая современная технология обеспечения безопасности платежей по картам в сети интернет. Позволяет однозначно идентифицировать подлинность держателя карты, осуществляющего операцию, и максимально снизить риск мошеннических операций по карте.</div>
            
            <div className='privacy-h2'>Гарантии безопасности</div>
            <div className='privacy-text'>Процессинговый центр TipTop pay защищает и обрабатывает данные Вашей банковской карты по стандарту безопасности PCI DSS 3.0. Передача информации в платежный шлюз происходит с применением технологии шифрования SSL. Дальнейшая передача информации происходит по закрытым банковским сетям, имеющим наивысший уровень надежности. TipTop pay не передает данные Вашей карты нам и иным третьим лицам. Для дополнительной аутентификации держателя карты используется протокол 3-D Secure.</div>
            <div className='privacy-text'>В случае, если у Вас есть вопросы по совершенному платежу, Вы можете обратиться в службу поддержки клиентов платежного сервиса по электронной почте support@cloudpayments.kz.</div>
            
            <div className='privacy-h2'>Безопасность онлайн-платежей</div>
            <div className='privacy-text'>Предоставляемая Вами персональная информация (имя, адрес, телефон, e-mail, номер кредитной карты) является конфиденциальной и не подлежит разглашению. Данные Вашей кредитной карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.</div>
            <div className='privacy-text'>Безопасность обработки Интернет-платежей гарантирует ТОО «TipTop Pay Kazakhstan». Все операции с платежными картами происходят в соответствии с требованиями VISA International, MasterCard и других платежных систем. При передаче информации используются специализированные технологии безопасности карточных онлайн-платежей, обработка данных ведется на безопасном высокотехнологичном сервере процессинговой компании.</div>
            <div className='privacy-text'>Оплата платежными картами безопасна, потому что:</div>
            <div className='privacy-text'>- Система авторизации гарантирует покупателю, что платежные реквизиты его платежной карты (номер, срок действия, CVV2/CVC2) не попадут в руки мошенников, так как эти данные не хранятся на сервере авторизации и не могут быть похищены.</div>
            <div className='privacy-text'>- Покупатель вводит свои платежные данные непосредственно в системе авторизации CloudPayments, а не на сайте интернет-магазина, следовательно, платежные реквизиты карточки покупателя не будут доступны третьим лицам.</div>
        
            <div className='privacy-h2'>Правила доставки</div>
            <div className='privacy-text'>Условия доставки:</div>
            <div className='privacy-text'>1. Стоимость доставки: Доставка осуществляется за счет покупателя.</div>
            <div className='privacy-text'>2. Сроки доставки: Сроки доставки зависят от выбранного способа и транспортной компании. Мы предоставляем несколько вариантов на выбор.</div>
            <div className='privacy-text'>3. Отправка заказа: Заказы отправляются в течение 1-2 рабочих дней после подтверждения оплаты.</div>
            <div className='privacy-text'>4. Отслеживание заказа: После отправки заказа мы предоставим номер для отслеживания, чтобы вы могли следить за местоположением вашей посылки.</div>
            <div className='privacy-text'>5. Будущие изменения: Мы понимаем важность удобной доставки для наших клиентов и в будущем планируем организовать доставку за наш счет.</div>
            <div className='privacy-text'>Если у вас есть вопросы или пожелания по поводу доставки, пожалуйста, свяжитесь с нашей службой поддержки. Мы всегда рады помочь!</div>
        
            <div className='privacy-h2'>Возврат денежных средств</div>
            <div className='privacy-text'>При проведении онлайн-оплаты посредством платежных карт не допускается возврат наличными денежными средствами. Порядок возврата регулируется правилами международных платежных систем:</div>
            <div className='privacy-text'>1. Потребитель вправе отказаться от товара в любое время до его передачи, после передачи товара отказ необходимо оформить в течение 14 дней;</div>
            <div className='privacy-text'>2. Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара;</div>
            <div className='privacy-text'>3. Потребитель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его человеком;</div>
            <div className='privacy-text'>При отказе от товара со стороны потребителя продавец должен вернуть ему денежную сумму, уплаченную потребителем, не позднее чем через десять дней со дня предъявления потребителем соответствующего требования.</div>
            <div className='privacy-text'>Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате денежных средств», которое высылается по требованию компанией на электронный адрес, и отправить его вместе с приложением копии документа, удостоверяющего личность, по адресу maisonmarklen@gmail.com.</div>
            <div className='privacy-text'>Возврат денежных средств будет осуществлен на банковскую карту в течение ___ рабочего дня со дня получения «Заявление о возврате денежных средств» Компанией.</div>
            <div className='privacy-text'>Для возврата денежных средств по операциям, проведенным с ошибками, необходимо обратиться с письменным заявлением и приложением копии документа, удостоверяющего личность, и чеков/квитанций, подтверждающих ошибочное списание. Данное заявление необходимо направить по адресу maisonmarklen@gmail.com.</div>
            <div className='privacy-text'>Сумма возврата будет равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных средств начинает исчисляться с момента получения Компанией Заявления и рассчитывается в рабочих днях без учета праздников/выходных дней.</div>
        
            <div className='privacy-h2'>Случаи отказа в совершении платежа:</div>
            <div className='privacy-text'>- банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать, обратившись в Ваш Банк-эмитент;</div>
            <div className='privacy-text'>недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на платежной карте Вы можете узнать, обратившись в банк, выпустивший банковскую карту;</div>
            <div className='privacy-text'>данные банковской карты введены неверно;</div>
            <div className='privacy-text'>истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой стороне карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия карты Вы можете узнать, обратившись в банк-эмитент.</div>
            <div className='privacy-text'>По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете обратиться по следующим телефонам: +7 (775) 634-46-65</div>

            <div className='privacy-h2'>Конфиденциальность</div>
            <div className='privacy-text'>1. Определения</div>
            <div className='privacy-text'>Интернет проект https://maisonmarclen.com (далее – URL, «мы») серьезно относится к вопросу конфиденциальности информации своих клиентов и посетителей сайта https://maisonmarclen.com (далее – «вы», «посетители сайта»). Персонифицированной мы называем информацию, содержащую персональные данные (например: ФИО, логин или название компании) посетителя сайта, а также информацию о действиях, совершаемых вами на сайте URL. (например: заказ посетителя сайта с его контактной информацией). Анонимными мы называем данные, которые невозможно однозначно идентифицировать с конкретным посетителем сайта (например: статистика посещаемости сайта).</div>
            <div className='privacy-text'>2. Использование информации</div>
            <div className='privacy-text'>Мы используем персонифицированную информацию конкретного посетителя сайта исключительно для обеспечения ему качественного оказания услуг и их учета. Мы не раскрываем персонифицированных данных одних посетителей сайта URL другим посетителям сайта. Мы никогда не публикуем персонифицированную информацию в открытом доступе и не передаем ее третьим лицам. Исключением являются лишь ситуации, когда предоставление такой информации уполномоченным государственным органам предписано действующим законодательством Республики Казахстан. Мы публикуем и распространяем только отчеты, построенные на основании собранных анонимных данных. При этом отчеты не содержат информацию, по которой было бы возможным идентифицировать персонифицированные данные пользователей услуг. Мы также используем анонимные данные для внутреннего анализа, целью которого является развитие продуктов и услуг URL</div>
            <div className='privacy-text'>3. Ссылки</div>
            <div className='privacy-text'>Сайт https://maisonmarclen.com может содержать ссылки на другие сайты, не имеющие отношения к нашей компании и принадлежащие третьим лицам. Мы не несем ответственности за точность, полноту и достоверность сведений, размещенных на сайтах третьих лиц, и не берем на себя никаких обязательств по сохранению конфиденциальности информации, оставленной вами на таких сайтах.</div>
            <div className='privacy-text'>4. Ограничение ответственности</div>
            <div className='privacy-text'>Мы делаем все возможное для соблюдения настоящей политики конфиденциальности, однако, мы не можем гарантировать сохранность информации в случае воздействия факторов находящихся вне нашего влияния, результатом действия которых станет раскрытие информации. Сайт https://maisonmarclen.com и вся размещенная на нем информация представлены по принципу "как есть” без каких-либо гарантий. Мы не несем ответственности за неблагоприятные последствия, а также за любые убытки, причиненные вследствие ограничения доступа к сайту URL или вследствие посещения сайта и использования размещенной на нем информации.</div>
            <div className='privacy-text'>5. Контакты</div>
            <div className='privacy-text'>По вопросам, касающимся настоящей политики, просьба обращаться по адресу maisonmarklen@gmail.com.</div>
        
            <div className='privacy-h2'>Юридическое лицо</div>
            <div className='privacy-text'>Название юридического лица: ИП Марклен</div>
            <div className='privacy-text'>ИИН/БИН: 950128350011</div>
            <div className='privacy-text'>Номер счета: KZ128562204138038485</div>
            <div className='privacy-text'>БИК: KCJBKZKX</div>
            <div className='privacy-text'>Адрес: Жамбыла 26</div>   
        </div>

        <Footer />
    </div>
  )
}

export default Terms