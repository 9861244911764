import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='footer-container'>
        <div className='footer-logo'>
            Maison Marclen
        </div>

        <div className='footer-bottom'>
            <Link className='footer-policy' to='/policy'>Terms and Conditions</Link>
            <div>Copyright © 2024</div>
        </div>
    </div>
  )
}

export default Footer