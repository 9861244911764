// src/pages/ProductPage.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { useCart } from '../../context/CartContext';
import AddedPopup from '../../components/AddedPopup';
import './index.css';
import Footer from '../../components/Footer';
import { formatPrice } from '../../utils/formatPrice';

interface Size {
  size: string;
  quantity: number;
}

interface Product {
  id: string;
  name: string;
  media: string[];
  price: string;
  size: Size[];
  description: string;
}

const ProductPage: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<Product | undefined>();
  const [selectedSize, setSelectedSize] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1);
  const { addToCart, addedItem } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      if (productId) {
        const productRef = doc(db, 'products', productId);
        const productSnap = await getDoc(productRef);
        if (productSnap.exists()) {
          const fetchedProduct = { id: productSnap.id, ...productSnap.data() } as Product;
          setProduct(fetchedProduct);
          if (fetchedProduct.size.length > 0) {
            setSelectedSize(fetchedProduct.size[0]?.size || ''); // Set default size
          }
        } else {
          console.log('No such product!');
        }
      }
    };

    fetchProduct();
  }, [productId]);

  const handleAddToCart = () => {
    if (product && selectedSize) {
      const sizeObject = product.size.find(s => s.size === selectedSize);
      if (sizeObject && sizeObject.quantity > 0) {
        addToCart({
          id: product.id,
          name: product.name,
          image: product.media[0],
          size: selectedSize,
          price: product.price,
          quantity
        });
      } else {
        alert('Selected size is out of stock');
      }
    }
  };

  const handleOrder = () => {
    if (product && selectedSize) {
      handleAddToCart();
      navigate('/checkout');
    }
  };

  const selectedSizeObject = product?.size.find(s => s.size === selectedSize);

  if (!product) {
    return <div></div>;
  }

  return (
    <div className='container'>
      <Header />
      <div className='product-page'>
        <img className='product-image' src={product.media[0]} alt={product.name} />
        <div className='product-details'>
          <div className='product-details-name'>{product.name}</div>
          <p className='product-price'>{formatPrice(product.price)} тг</p>
          <div className='product-sizes'>
            {product.size.map(({ size, quantity }) => (
              <button
                key={size}
                className={`size-button ${size === selectedSize ? 'selected' : ''}`}
                onClick={() => {
                  setSelectedSize(size);
                  setQuantity(1); // Reset quantity when size changes
                }}
                disabled={quantity === 0}
              >
                {size}, {quantity} шт.
              </button>
            ))}
          </div>
          <div className='product-quantity'>
            <button onClick={() => setQuantity(prev => Math.max(1, prev - 1))}>-</button>
            <span>{quantity}</span>
            <button onClick={() => setQuantity(prev => Math.min(selectedSizeObject?.quantity || 1, prev + 1))}>+</button>
          </div>
          <div className='product-buttons'>
            {selectedSizeObject && selectedSizeObject.quantity > 0 ? (
              <>
                <button className='add-to-cart' onClick={handleAddToCart}>Добавить в корзину</button>
                <button className='buy' onClick={handleOrder}>Заказать</button>
              </>
            ) : (
              <button className='out-of-stock' disabled>Нет в наличии</button>
            )}
          </div>
          <div className='product-details-description'>{product.description}</div>
        </div>
      </div>
      {addedItem && (
        <AddedPopup
          productName={addedItem.productName}
          quantity={addedItem.quantity}
          price={addedItem.price}
          image={addedItem.image}
          size={addedItem.size}
          visible={!!addedItem}
        />
      )}
      <Footer />
    </div>
  );
};

export default ProductPage;
