
import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';

interface AddedPopupProps {
  productName: string;
  quantity: number;
  price: string;
  image: string;
  size: string;
  visible: boolean;
}

const AddedPopup: React.FC<AddedPopupProps> = ({ productName, quantity, price, image, size, visible }) => {
  const navigate = useNavigate();
  if (!visible) return null;


  const navigateToCart = () => {
    navigate(`/cart`);
  };

  return (
    <div className='added-popup' onClick={navigateToCart}>
      <img src={image} alt={productName} className='added-popup-image' />
      <div>
        <div  className='added-popup-main'>
        {quantity} x {productName} добавлен в корзину.
       </div>

        <div className='added-popup-additional'>
            <div>
            Размер: {size}
            </div>

            <div>
            Цена: {price} тг
            </div>
       </div>
      </div>
    </div>
  );
};

export default AddedPopup;
