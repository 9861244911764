// src/pages/CartPage.tsx
import React from 'react';
import { useCart } from '../../context/CartContext';
import Header from '../../components/Header';
import './index.css';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../utils/formatPrice';

const CartPage: React.FC = () => {
  const { cart, removeFromCart, clearCart } = useCart();
  const navigate = useNavigate();

  const handleRemoveFromCart = (id: string, size: string) => {
    removeFromCart(id, size);
  };

  const handleCheckout = () => {
    navigate('/checkout');
  };

  const totalSum = cart.reduce((sum, item) => {
    const price = parseFloat(item.price.replace(/\D/g, ''));
    return sum + price * item.quantity;
  }, 0);

  return (
    <div className='container'>
      <Header />
      <div className='cart-page'>
        <h1>Корзина</h1>
        {cart.length === 0 ? (
          <p>Ваша корзина пуста</p>
        ) : (
          <div className='cart-items'>
            {cart.map((item, index) => (
              <div key={index} className='cart-item'>
                <img className='cart-media' src={item.image} alt={item.name} />
                <div className='cart-info'>
                  <div className='cart-info-row'>
                    <div>{item.name}</div>
                    <div>{formatPrice(item.price)} тг</div>
                  </div>
                  <div>
                    <div>Размер: {item.size}</div>
                    <div>Количество: {item.quantity}</div>
                  </div>
                  <button className='cart-info-delete' onClick={() => handleRemoveFromCart(item.id, item.size)}>Удалить</button>
                </div>
              </div>
            ))}
            <h3>Итого: {totalSum} тг</h3>
            <div className='cart-buttons'>
              <button className='cart-clear' onClick={clearCart}>Очистить корзину</button>
              <button className='checkout' onClick={handleCheckout}>Заказать</button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CartPage;
