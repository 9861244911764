// src/routes/AdminPanel.tsx
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, deleteDoc, doc, updateDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../firebase';
import Login from '../../components/Login';
import './index.css';
import AdminHeader from '../../components/AdminHeader';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

interface Product {
  id: string;
  name: string;
  description: string;
  size: { size: string; quantity: number }[];
  price: string;
  media: string[];
}
const AdminPanel: React.FC = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentProductId, setCurrentProductId] = useState<string | null>(null);
  const [newProduct, setNewProduct] = useState<Omit<Product, 'id'>>({
    name: '',
    description: '',
    size: [],
    price: '',
    media: [],
  });
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [newSize, setNewSize] = useState<string>('');
  const [newQuantity, setNewQuantity] = useState<number>(0);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const updateProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'products'));
      querySnapshot.forEach(async (docSnapshot) => {
        const data = docSnapshot.data();
        if (Array.isArray(data.size) && data.size.length > 0 && typeof data.size[0] === 'string') {
          const updatedSizes = data.size.map((size: string) => ({ size, quantity: 100 }));
          await updateDoc(doc(db, 'products', docSnapshot.id), { size: updatedSizes });
        }
      });
    };
    
    updateProducts();
    }, [])

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsData: Product[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        productsData.push({ id: doc.id, ...data, size: data.size || [], media: data.media || [] } as Product);
      });
      setProducts(productsData);
    };

    if (user && user.uid === 'TzhN93zkyBbJ4o4pOARvbLInc502') {
      fetchProducts();
    }
  }, [user]);

  if (!user) {
    return <div></div>;
  }

  if (user.uid !== 'TzhN93zkyBbJ4o4pOARvbLInc502') {
    return <p>Access Denied</p>;
  }

  const handleOpenPopup = (product?: Partial<Product>) => {
    if (product) {
      setCurrentProductId(product.id || null);
      setNewProduct({
        name: product.name || '',
        price: product.price || '',
        description: product.description || '',
        media: product.media || [],
        size: product.size || [],
      });
      setIsEditMode(true);
    } else {
      setCurrentProductId(null);
      setNewProduct({
        name: '',
        price: '',
        description: '',
        media: [],
        size: [],
      });
      setIsEditMode(false);
    }
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDeleteProduct = async (id: string) => {
    await deleteDoc(doc(db, 'products', id));
    setIsPopupOpen(false);
    setCurrentProductId(null);
    setNewProduct({
      name: '',
      price: '',
      description: '',
      media: [],
      size: [],
    });
    setProducts(products.filter(product => product.id !== id));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const refetch = async () => {
    const querySnapshot = await getDocs(collection(db, 'products'));
    const productsData: Product[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      productsData.push({ id: doc.id, ...data, size: data.size || [], media: data.media || [] } as Product);
    });
    setProducts(productsData);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const productData = { ...newProduct, createdAt: serverTimestamp() };

    const newImages = newProduct.media.filter(image => image.startsWith('blob:'));
    const existingImages = newProduct.media.filter(image => !image.startsWith('blob:'));

    const uploadedNewImages = await Promise.all(
      newImages.map(async (image, index) => {
        const response = await fetch(image);
        const blob = await response.blob();
        const storageRef = ref(storage, `products/${newProduct.name}/image_${index}`);
        await uploadBytes(storageRef, blob);
        return await getDownloadURL(storageRef);
      })
    );

    productData.media = [...existingImages, ...uploadedNewImages];

    if (isEditMode && currentProductId) {
      const productRef = doc(db, 'products', currentProductId);
      await updateDoc(productRef, productData);
    } else {
      const newProductRef = doc(collection(db, 'products'));
      await setDoc(newProductRef, { ...productData, id: newProductRef.id });
    }
    refetch();
    setLoading(false);
    console.log('Product saved:', productData);
    setIsPopupOpen(false);
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newImages = Array.from(files).map((file) => URL.createObjectURL(file));
      setNewProduct({ ...newProduct, media: [...newProduct.media, ...newImages] });
    }
  };

  const handleDragStart = (index: number) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (index: number) => {
    if (draggingIndex !== null && draggingIndex !== index) {
      const newImages = [...newProduct.media];
      const [draggedImage] = newImages.splice(draggingIndex, 1);
      newImages.splice(index, 0, draggedImage);
      setDraggingIndex(index);
      setNewProduct({ ...newProduct, media: newImages });
    }
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
  };

  const handleAddSize = () => {
    if (newSize.trim() && newQuantity > 0) {
      setNewProduct({ ...newProduct, size: [...newProduct.size, { size: newSize, quantity: newQuantity }] });
      setNewSize('');
      setNewQuantity(0);
    }
  };

  const handleSizeChange = (index: number, field: 'size' | 'quantity', value: string | number) => {
    const updatedSize = [...newProduct.size];
    updatedSize[index] = { ...updatedSize[index], [field]: value };
    setNewProduct({ ...newProduct, size: updatedSize });
  };

  return (
    <div className="admin-panel">
      <AdminHeader />

      {loading && (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      )}

      {!isPopupOpen && (
        <div className='admin'>
          <div className='catalog-title'>Панель продуктов</div>
          <div className='table'>
            <div className='table-top'>
              {products.length} товаров
              <button onClick={() => handleOpenPopup()}>Добавить товары</button>
            </div>
            <div className='custom-table'>
              <div className='custom-table-body'>
                {products.map((product, index) => (
                  <div className={`custom-table-row`} key={index} onClick={() => handleOpenPopup(product)}>
                    <div className='custom-table-cell'>
                      <img src={product.media[0]} alt={product.name} />
                      {product.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {isPopupOpen && (
        <div className='popup'>
          <div className='popup-inner'>
            <div className='popup-header'>
              <button onClick={handleClosePopup}>
                <ArrowBackOutlinedIcon />
              </button>
              <div>{isEditMode ? `${newProduct.name}` : 'Добавить новый товар'}</div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className='popup-block'>
                <div className='popup-block-form'>
                  <div className='popup-block-title'>Описание</div>
                  <label>Название</label>
                  <input
                    type='text'
                    name='name'
                    placeholder='Название'
                    onChange={handleChange}
                    value={newProduct.name}
                    required
                  />
                  <label>Описание</label>
                  <textarea
                    name='description'
                    placeholder='Описание'
                    onChange={handleChange}
                    value={newProduct.description}
                    required
                  ></textarea>
                </div>
              </div>
              <div className='popup-block'>
                <div className='popup-block-form'>
                  <div className='popup-block-title'>Изображения</div>
                  <div className='popup-block-images'>
                    <label htmlFor='uploadhidden'>
                      <DriveFolderUploadOutlinedIcon />
                    </label>
                    <input
                      type='file'
                      id='uploadhidden'
                      multiple
                      accept='image/*'
                      onChange={handleImageUpload}
                      style={{ display: 'none' }}
                    />
                    {newProduct.media.map((image, index) => (
                      <div
                        key={index}
                        className='uploaded-image'
                        draggable
                        onDragStart={() => handleDragStart(index)}
                        onDragOver={(e) => {
                          e.preventDefault();
                          handleDragOver(index);
                        }}
                        onDragEnd={handleDragEnd}
                      >
                        <img src={image} alt={`Uploaded ${index}`} />
                      </div>
                    ))}
                  </div>
                </div>
                <div className='popup-block-form'>
                  <div className='popup-block-title'>Цена и Размеры</div>
                  <div style={{ flexDirection: 'column', display: 'flex', flex: '1' }}>
                    <label>Актуальная цена</label>
                    <input
                      type='number'
                      name='price'
                      placeholder='Актуальная цена'
                      onChange={handleChange}
                      value={newProduct.price}
                      required
                    />
                  </div>
                  <div style={{ flexDirection: 'column', display: 'flex', flex: '1' }}>
                    <label>Размеры</label>
                    <div className='popup-block-size'>
                      {newProduct.size.map((sizeObj, index) => (
                        <div key={index} className="size-quantity-pair">
                          <input
                            type='text'
                            placeholder='Размер'
                            value={sizeObj.size}
                            onChange={(e) => handleSizeChange(index, 'size', e.target.value)}
                          />
                          <input
                            type='number'
                            placeholder='Количество'
                            value={sizeObj.quantity}
                            onChange={(e) => handleSizeChange(index, 'quantity', Number(e.target.value))}
                          />
                        </div>
                      ))}
                    </div>
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <input
                        style={{ margin: 0, flex: '1' }}
                        type='text'
                        placeholder='Новый размер'
                        value={newSize}
                        onChange={(e) => setNewSize(e.target.value)}
                      />
                      <input
                        style={{ margin: 0, flex: '1' }}
                        type='number'
                        placeholder='Количество'
                        value={newQuantity}
                        onChange={(e) => setNewQuantity(Number(e.target.value))}
                      />
                      <button type='button' onClick={handleAddSize}>Добавить размер</button>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '30px' }}>
                  {isEditMode && currentProductId && (
                    <button style={{ marginRight: '5px' }} onClick={() => handleDeleteProduct(currentProductId)}>
                      Удалить продукт
                    </button>
                  )}
                  <button type='submit'>{isEditMode ? 'Обновить' : 'Сохранить'}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
