// src/pages/Checkout.tsx
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import './index.css';
import Footer from '../../components/Footer';
import { useCart } from '../../context/CartContext';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../utils/formatPrice';

interface Address {
  firstName: string;
  lastName: string;
  exactAddress: string;
  country: string;
  city: string;
  postalCode: string;
  phone: string;
  email: string;
}

const kazakhstanCities = [
    "Almaty", "Astana", "Shymkent", "Aktobe", "Karaganda", "Taraz", "Pavlodar", "Oskemen", 
    "Semey", "Atyrau", "Kostanay", "Kyzylorda", "Oral", "Petropavl", "Aktau", "Temirtau", 
    "Turkistan", "Kokshetau", "Taldykorgan", "Ekibastuz", "Rudny", "Zhanaozen", "Stepnogorsk", "Zhezkazgan", 
    "Kaskelen", "Saran", "Balkhash", "Zyryanovsk", "Ridder", "Kapshagay", "Satbayev", "Aksu", "Ayagoz", "Kulsary", 
    "Zhangatas", "Shakhtinsk", "Karatau", "Lenger", "Arys", "Zhitikara", "Kentau", "Saryagash", "Shu", 
    "Makinsk", "Esik", "Stepnyak", "Derzhavinsk", "Atbasar", "Shalkar", "Bulaevo", "Turar Ryskulov"
];
const countries = [
    "Kazakhstan", "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", 
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", 
    "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", 
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon", 
    "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo (Congo-Brazzaville)", 
    "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czechia (Czech Republic)", "Democratic Republic of the Congo", 
    "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", 
    "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", 
    "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", 
    "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", 
    "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", 
    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", 
    "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", 
    "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", 
    "Mozambique", "Myanmar (formerly Burma)", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", 
    "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman", "Pakistan", 
    "Palau", "Palestine State", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", 
    "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", 
    "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", 
    "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", 
    "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", 
    "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", 
    "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", 
    "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", 
    "Vanuatu", "Vatican City", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];


const Checkout: React.FC = () => {
  const navigate = useNavigate();
  const { cart, clearCart } = useCart();
  const { isCartEmpty } = useCart();
  const [address, setAddress] = useState<Address>({
    firstName: '',
    lastName: '',
    exactAddress: '',
    country: 'Kazakhstan',
    city: '',
    postalCode: '',
    phone: '',
    email: ''
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [deliveryPrice, setDeliveryPrice] = useState(0);

  const totalSum = cart.reduce((sum, item) => {
    const price = parseFloat(item.price.replace(/\D/g, ''));
    return sum + price * item.quantity;
  }, 0);

  useEffect(() => {
    if (address.country === 'Kazakhstan') {
      if (address.city === 'Almaty') {
        setDeliveryPrice(10);
      } else if (kazakhstanCities.includes(address.city)) {
        setDeliveryPrice(1800);
      } else {
        setDeliveryPrice(0);
      }
    } else {
      setDeliveryPrice(3600);
    }
  }, [address.country, address.city]);

  useEffect(() => {
    if (isCartEmpty) {
        navigate('/cart')
    }
  }, []);

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
    setErrors(errors.filter(error => error !== name));
  };

  const handleProceedToPayment = () => {
    const requiredFields: (keyof Address)[] = ['firstName', 'lastName', 'exactAddress', 'country', 'city', 'postalCode', 'phone', 'email'];
    const newErrors = requiredFields.filter(field => !address[field]);

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    handlePayment();
  };

  const handlePayment = () => {
    const widget = new (window as any).tiptop.Widget();
    widget.pay('charge',
      {
        publicId: 'pk_6a3f3296193b08946f8d2670f9206',
        description: "Оплата заказа",
        amount: totalSum + deliveryPrice,
        currency: 'KZT',
        invoiceId: '1234567',
        email: address.email,
        accountId: address.email,
        skin: "classic",
        autoClose: 9,
        data: { 
            firstName: address.firstName,
            lastName: address.lastName,
            address: address.exactAddress,
            city: address.city,
            country: address.country,
            phone: address.phone,
            postcode: address.postalCode,
            cart: cart
        }
      },
      {
        onSuccess: function (options: any) {
          clearCart();
          navigate('/')
        },
        onFail: function (reason: any, options: any) {
          console.error('Payment failed');
        },
        onComplete: function (paymentResult: any, options: any) {
          console.log('Payment complete');
        }
      }
    );
  };

  return (
    <div className='container'>
      <Header />
      <div className='checkout-page'>
        <div className='checkout-page-block'>
          <div className='checkout-page-block-title'>ИТОГОВАЯ СУММА</div>
          {cart.map((item, index) => (
            <div key={index} className='checkout-page-block-row'>
              <img className='checkout-page-block-product-image' src={item.image} />
              <div className='checkout-page-block-product'>{item.name}, {item.size}</div>
              <div className='checkout-page-block-price'>{formatPrice(item.price)} тг</div>
            </div>
          ))}
          <div className='checkout-page-block-row-ship'>
            <div className='checkout-page-block-product'>Доставка</div>
            <div className='checkout-page-block-price'>
                {deliveryPrice === 0 ? 'Выберите страну и город' : `${deliveryPrice} тг`}
            </div>
          </div>
          <div className='checkout-page-block-row-total'>
            <div className='checkout-page-block-product'>Итого</div>
            <div className='checkout-page-block-price'>{totalSum + deliveryPrice} тг</div>
          </div>
        </div>
        
        <div className='checkout-page-block'>
          <div className='checkout-page-block-title'>ОФОРМЛЕНИЕ ЗАКАЗА</div>
          <div className='checkout-page-block-inputs'>
            <div className='checkout-page-block-input'>
              <label style={{ color: errors.includes('firstName') ? '#ff9696' : 'initial' }}>Имя</label>
              <input 
                name="firstName" 
                value={address.firstName} 
                onChange={handleAddressChange} 
                placeholder='Введите ваше имя'
                style={{ borderColor: errors.includes('firstName') ? '#ff9696' : '#bbbbbb' }}
              />
            </div>
            <div className='checkout-page-block-input'>
              <label style={{ color: errors.includes('lastName') ? '#ff9696' : 'initial' }}>Фамилия</label>
              <input 
                name="lastName" 
                value={address.lastName} 
                onChange={handleAddressChange} 
                placeholder='Введите фамилию'
                style={{ borderColor: errors.includes('lastName') ? '#ff9696' : '#bbbbbb' }}
              />
            </div>
          </div>
          <div className='checkout-page-block-input-single'>
            <label style={{ color: errors.includes('exactAddress') ? '#ff9696' : 'initial' }}>Точный адрес</label>
            <input 
              name="exactAddress" 
              value={address.exactAddress} 
              onChange={handleAddressChange} 
              placeholder='Точный адрес'
              style={{ borderColor: errors.includes('exactAddress') ? '#ff9696' : '#bbbbbb' }}
            />
          </div>
          <div className='checkout-page-block-inputs'>
            <div className='checkout-page-block-input'>
              <label style={{ color: errors.includes('country') ? '#ff9696' : 'initial' }}>Страна</label>
              <select 
                name="country" 
                value={address.country} 
                onChange={handleAddressChange}
                style={{ borderColor: errors.includes('country') ? '#ff9696' : '#bbbbbb' }}
              >
                {countries.map(country => (
                  <option key={country} value={country}>{country}</option>
                ))}
              </select>
            </div>
            <div className='checkout-page-block-input'>
              <label style={{ color: errors.includes('city') ? '#ff9696' : 'initial' }}>Город</label>
              {address.country === 'Kazakhstan' ? (
                <select 
                  name="city" 
                  value={address.city} 
                  onChange={handleAddressChange}
                  style={{ borderColor: errors.includes('city') ? '#ff9696' : '#bbbbbb' }}
                >
                  <option value="">Выберите город</option>
                  {kazakhstanCities.map(city => (
                    <option key={city} value={city}>{city}</option>
                  ))}
                </select>
              ) : (
                <input 
                  name="city" 
                  value={address.city} 
                  onChange={handleAddressChange}
                  placeholder='Город'
                  style={{ borderColor: errors.includes('city') ? '#ff9696' : '#bbbbbb' }}
                />
              )}
            </div>
          </div>
          <div className='checkout-page-block-inputs'>
            <div className='checkout-page-block-input'>
              <label style={{ color: errors.includes('postalCode') ? '#ff9696' : 'initial' }}>Индекс</label>
              <input 
                name="postalCode" 
                value={address.postalCode} 
                onChange={handleAddressChange}
                placeholder='Индекс'
                style={{ borderColor: errors.includes('postalCode') ? '#ff9696' : '#bbbbbb' }}
              />
            </div>
            <div className='checkout-page-block-input'>
              <label style={{ color: errors.includes('phone') ? '#ff9696' : 'initial' }}>Телефон</label>
              <input 
                name="phone" 
                value={address.phone} 
                onChange={handleAddressChange}
                placeholder='Телефон'
                style={{ borderColor: errors.includes('phone') ? '#ff9696' : '#bbbbbb' }}
              />
            </div>
          </div>
          <div className='checkout-page-block-input-single'>
            <label style={{ color: errors.includes('email') ? '#ff9696' : 'initial' }}>Электронная почта</label>
            <input 
              name="email" 
              value={address.email} 
              onChange={handleAddressChange} 
              placeholder='Электронная почта'
              style={{ borderColor: errors.includes('email') ? '#ff9696' : '#bbbbbb' }}
            />
          </div>
          <button className='checkout' onClick={handleProceedToPayment}>Оплатить</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
