import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, query, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import Login from '../../components/Login';
import './index.css';
import AdminHeader from '../../components/AdminHeader';

interface Order {
  id: string;
  orderId: string;
  cart: CartItem[];
  firstName: string;
  lastName: string;
  exactAddress: string;
  city: string;
  country: string;
  phone: string;
  email: string;
  postalCode: string;
  amount: number;
  createdAt: any;
  status: string;
}

interface CartItem {
  name: string;
  size: string;
  quantity: number;
}

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      const ordersCollection = collection(db, 'orders');
      const ordersQuery = query(ordersCollection, orderBy('createdAt', 'desc'));
      const ordersSnapshot = await getDocs(ordersQuery);
      const ordersList: Order[] = ordersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Order));
      setOrders(ordersList);
    };

    fetchOrders();
  }, []);

  const handleStatusChange = async (orderId: string) => {
    const orderRef = doc(db, 'orders', orderId);
    await updateDoc(orderRef, {
      status: 'completed'
    });
    setOrders(orders.map(order => order.id === orderId ? { ...order, status: 'completed' } : order));
  };

  if (!user) {
    return <Login />;
  }

  if (user.uid !== 'TzhN93zkyBbJ4o4pOARvbLInc502') {
    return <p>Access Denied</p>;
  }

  const formatDateTime = (timestamp: any) => {
    const date = new Date(timestamp.seconds * 1000);
    const formattedDate = date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    });
    const formattedTime = date.toLocaleTimeString('ru-RU', {
      hour: '2-digit',
      minute: '2-digit'
    });
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="admin-panel">
      <AdminHeader />
      <div className="order-table">
      <div className="order-title">Заказы</div>
        <div className="table-header">
          <div>Order ID</div>
          <div>Покупатель</div>
          <div>Товары</div>
          <div>Сумма</div>
          <div>Дата покупки</div>
          <div>Адрес</div>
          <div>Статус</div>
          <div>Действие</div>
        </div>
        {orders.map(order => (
          <div className="table-row" key={order.id}>
            <div>{order.orderId}</div>
            <div style={{ fontSize: '12px' }}>{order.firstName} {order.lastName}, {order.phone}, {order.email}</div>
            <div>
            {order.cart?.map((item, index) => (
                <div style={{ fontSize: '10.5px' }} key={index}>
                  {Array(item.quantity).fill(null).map((_, i) => (
                    <div key={i}>{item.name}, {item.size}, {item.quantity} шт.</div>
                  ))}
                </div>
              ))}
            </div>
            <div>{order.amount} тг</div>
            <div>{formatDateTime(order.createdAt)}</div>
            <div style={{ fontSize: '10px' }}>{order.exactAddress}, {order.city}, {order.country}, {order.postalCode}</div>
            {order.status === 'paid' && 
                <div style={{fontWeight: '600', color: 'orange'}}>В обработке</div>
            }
           {order.status === 'completed' && 
                <div style={{fontWeight: '600', color: 'green'}}>Отправлено</div>
            }
            <div>
              <button className='checkout' onClick={() => handleStatusChange(order.id)}>Отправлено</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Orders;
