import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './routes/Home/';
import ProductPage from './routes/Product';
import { CartProvider } from './context/CartContext';
import Cart from './routes/Cart';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import AdminPanel from './routes/Admin';
import Terms from './routes/Policies/Terms';
import Checkout from './routes/Checkout';
import Orders from './routes/Orders';
import ErrorBoundary from './context/ErrorBoudnry';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <CartProvider>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/product/:productId" element={<ProductPage />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/policy" element={<Terms />} />
      <Route path="/adminpermission/products" element={<AdminPanel />} />
      <Route path="/adminpermission/" element={<Orders />} />
    </Routes>
  </BrowserRouter>
  </CartProvider>
);

