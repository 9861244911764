// src/components/ProductCard.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../context/CartContext';
import './index.css';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { formatPrice } from '../../utils/formatPrice';

interface Size {
  size: string;
  quantity: number;
}

interface ProductProps {
  product: {
    id: string;
    name: string;
    media: string[];
    size: Size[];
    price: string;
    description: string;
  };
}

const Product: React.FC<ProductProps> = ({ product }) => {
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const [selectedSize, setSelectedSize] = useState<string>(product.size[0].size);
  const [quantity, setQuantity] = useState<number>(1);

  const navigateToProductPage = () => {
    navigate(`/product/${product.id}`);
  };

  const handleAddToCart = () => {
    const sizeObject = product.size.find(s => s.size === selectedSize);
    if (sizeObject && sizeObject.quantity > 0) {
      addToCart({
        id: product.id,
        name: product.name,
        image: product.media[0],
        size: selectedSize,
        price: product.price,
        quantity
      });
    } else {
      alert('Selected size is out of stock');
    }
  };

  return (
    <div className='product-card'>
      <LazyLoadImage
        className='product-card-image'
        src={product.media[0]}
        alt={product.name}
        effect="blur"
        onClick={navigateToProductPage}
        placeholderSrc="/path/to/skeleton-image.png"
      />
      <div className='product-card-title' onClick={navigateToProductPage}>{product.name}</div>
      <div className='product-card-price' onClick={navigateToProductPage}>{formatPrice(product.price)} тг</div>
      <div className='sizes'>
        {product.size.map(( size, index) => (
          <button
            key={index}
            className={`card-size-button ${size.size === selectedSize ? 'selected' : ''}`}
            onClick={() => setSelectedSize(size.size)}
            disabled={size.quantity === 0}
          >
            {size.size}
          </button>
        ))}
      </div>
      <button className='product-card-add-to-cart' onClick={handleAddToCart}>Добавить в корзину</button>
    </div>
  );
};

export default Product;
