// src/components/Header.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/mmlogo.png';
import carticon from '../../assets/cart.png';
import './index.css';
import { useCart } from '../../context/CartContext';

const Header: React.FC = () => {
  const { cart } = useCart();

  const cartItemCount = cart.reduce((count, item) => count + item.quantity, 0);

  return (
    <div className='header'>
      <div className='header-menu'>
        <Link to='/' className='header-menu-item'>
          <img src={logo} className='logo' alt='logo' />
        </Link>
        <Link to='/cart' className='header-menu-item'>
          <div className='cart-container'>
            <img src={carticon} className='cart-icon' alt='cart' />
            {cartItemCount > 0 && (
              <div className='cart-count'>
                {cartItemCount}
              </div>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Header;
