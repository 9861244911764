// src/components/Header.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/mmlogo.png';

const AdminHeader: React.FC = () => {

  return (
    <div className='header'>
      <div className='header-menu'>
        <Link to='/' className='header-menu-item'>
          <img src={logo} className='logo' alt='logo' />
        </Link>
        <div className='header-menu-items'>
        <Link style={{textDecoration: 'none', color: 'black'}} to='/adminpermission' className='header-menu-item'>Заказы</Link>
        <Link style={{textDecoration: 'none', color: 'black'}} to='/adminpermission/products' className='header-menu-item'>Продукты</Link>
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
