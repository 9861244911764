import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBzYCnNhuPnQX4fiFpNDmId7EouIa5McYk",
  authDomain: "maisonmarclen.firebaseapp.com",
  projectId: "maisonmarclen",
  storageBucket: "maisonmarclen.appspot.com",
  messagingSenderId: "870318644960",
  appId: "1:870318644960:web:3937e9ffd2edcd2eb03a85"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);

export { db, storage };