// src/pages/Home.tsx
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Product from '../../components/ProductCard';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useCart } from '../../context/CartContext';
import AddedPopup from '../../components/AddedPopup';
import './index.css';
import Footer from '../../components/Footer';

interface Size {
  size: string;
  quantity: number;
}

interface ProductType {
  id: string;
  name: string;
  media: string[];
  size: Size[];
  price: string;
  description: string;
}

const Home: React.FC = () => {
  const [products, setProducts] = useState<ProductType[]>([]);
  const { addedItem } = useCart();

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsData: ProductType[] = [];
      querySnapshot.forEach((doc) => {
        productsData.push({ id: doc.id, ...doc.data() } as ProductType);
      });
      setProducts(productsData);
    };

    fetchProducts();
  }, []);

  return (
    <div className='container'>
      <Header />
      <div className='products'>
        {products.map(product => (
          <Product key={product.id} product={product} />
        ))}
      </div>
      {addedItem && (
        <AddedPopup
          productName={addedItem.productName}
          quantity={addedItem.quantity}
          price={addedItem.price}
          image={addedItem.image}
          size={addedItem.size}
          visible={!!addedItem}
        />
      )}
      <Footer />
    </div>
  );
};

export default Home;
